import de from '@/locales/de'
import en from '@/locales/en'
import fr_FR from '@/locales/fr_FR'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: {
    default: 'en'
  },
  messages: {
    en,
    fr_FR,
    de
  },
  silentFallbackWarn: true
})

export default {
  i18n: i18n,
  t: i18n.global.t,
  availableLocales: i18n.global.availableLocales,
  locale: i18n.global.locale
}
